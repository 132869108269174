import React from "react";
import logos from "./assets/images/logos.svg";
import kopilot from "./assets/images/kopilot.svg";

import logo from "./assets/logos/LUAR.png";

const Home2 = ({ person }) => {
  // {JSON.stringify(person, null, 2)}

  return (
    <div id="wrapper" className="home1">
      <div id="main">
        <h1>
          <em className="justify-content-end">
            <img src={logos} alt="logos" />
          </em>
          <br />
          <span>time</span>2
          <br />
          <em className="justify-content-center white">
            For the next level in transport
          </em>
        </h1>

        <div className="flex">
          <div className="flex-basis-small">
            <div>
              <h3 className="red" id="name">
                <span
                  dangerouslySetInnerHTML={{
                    __html: person?.name?.replace(" ", "<br />"),
                  }}
                ></span>
                <br />
                {/* <img src={logo} alt="client logo" /> */}
                <img src={person.logo} alt="client logo" />
              </h3>
              <h4>
                <span className="thin">
                  <span className="big">Time</span> <br />
                  <span className="bigger">2030</span>
                </span>
                <br />
                <strong>
                  Oameni care <br />
                  duc transporturile <br />
                  mai departe.
                </strong>
              </h4>
            </div>

            <div>
              <h4 className="red">
                E TIMPUL PENTRU
                <br />
                URMĂTORUL NIVEL
                <br />
                ÎN TRANSPORTURI.
              </h4>
              <p className="white">
                DKV, un pionier în carduri <br />
                de carburanți s-a unit <br />
                cu SMART DIESEL, <br />
                un brand nou și inovativ, <br />
                rezultând Smart Diesel <br />
                part of DKV.
              </p>
            </div>

            <div className="white">
              <h4>
                <span className="thin">
                  <span className="bigger2">2022</span>
                </span>
                <br />
                <strong>
                  Anul reinventării <br />
                  în transporturi
                </strong>
              </h4>
            </div>
          </div>

          <div className="align-right">
            <div>
              <img src={kopilot} alt="kopilot" className="kopilot" />
              <h4>
                <strong>
                  Aplicația care
                  <br />
                  reduce stresul
                  <br />
                  din industria
                  <br />
                  transporturilor.
                </strong>
              </h4>
              <p className="white">
                Alimentezi mereu <br />
                la cel mai mic preț!
              </p>
            </div>

            <div>
              <h4 className="red">
                Ingredientele <br />
                succesului <br />
                într-o afacere <br />
                de transport.
              </h4>
              <h4 className="white">
                Pasiunea <br />
                pentru <br />
                transporturi <br />
                și parteneri <br />
                care îți sunt <br />
                aproape.
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home2;
