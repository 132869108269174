import React from "react";
import Select from "react-select";
import { ref, set } from "firebase/database";
import { db } from "./firebase";
import "./Admin.css";

import logo from "./assets/logos/LUAR.png";
import kopilot from "./assets/images/kopilot.svg";

const Admin = () => {
  const handleSubmit = (e) => {
    e.preventDefault();

    const nameVal = e.target.elements.name.value;
    const logoVal = e.target.elements.logo.value;
    const formatVal = e.target.elements.format.value;

    const newValues = {
      name: nameVal,
      logo: logoVal,
      format: formatVal,
    };

    if (nameVal !== "") {
      newValues.name = nameVal;
    }

    if (logoVal !== "") {
      newValues.logo = logoVal;
    }

    if (formatVal !== "") {
      newValues.format = formatVal;
    }

    set(ref(db, "person"), newValues);
  };

  console.log(logo);

  const formatOptions = [
    { value: "time", label: "Time", image: logo },
    { value: "time-30", label: "Time 30", image: logo },
    { value: "entrepreneur", label: "Entrepreneur", image: logo },
  ];

  const logoOptions = [
    { value: logo, label: "Chocolate", image: logo },
    { value: kopilot, label: "Chocolate 2", image: kopilot },
  ];

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Select name="format" options={formatOptions} />

        <input type="text" name="name" placeholder="Nume" />

        <Select
          name="logo"
          options={logoOptions}
          formatOptionLabel={(country) => (
            <div className="country-option">
              <img src={country.image} alt="country-image" />
              <span>{country.label}</span>
            </div>
          )}
        />

        <button type="submit">Modifică</button>
      </form>
    </div>
  );
};

export default Admin;
