import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCe9vBqeWDQa3Qc_k8aQI7Ld8BgmpdJlpE",
  authDomain: "smart-52df3.firebaseapp.com",
  databaseURL:
    "https://smart-52df3-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "smart-52df3",
  storageBucket: "smart-52df3.appspot.com",
  messagingSenderId: "6492488329",
  appId: "1:6492488329:web:9a3408006c58b35b0d7c58",
};

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
